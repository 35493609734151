.profile-heading {
  color: #161e29;
  font-size: 30px;
  font-weight: 600;
  margin-left: 25px;
}

.main-wrapper {
  .head {
    h1 {
      color: #202123;
      font-size: 20px;
      font-weight: 500;
      margin-top: 30px;
    }
  }

  .accordianIcon {
    border-radius: 100%;
    width: 34px;
    height: 34px;
    background-color: #c7340d;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  .headings {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      color: #202123;
      font-size: 20px;
      font-weight: 400;
    }
  }

  p {
    color: rgba(32, 33, 35, 0.75);
    font-size: 16px;
    font-weight: 400;
    margin-top: -7px;
  }

  h6 {
    font-size: 18px;
    font-weight: 400;

  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    background-color: #fff !important
  }
  .MuiInputBase-input-MuiOutlinedInput-input{
    background-color: #fff !important
  }
  .MuiInputBase-root-MuiOutlinedInput-root{
    background-color: #fff !important
  }


}



@media screen and (max-width: 600px) {
  .profile-heading {
    color: #161e29;
    font-size: 20px;
    font-weight: 600;
    margin-left: 25px;
  }
}