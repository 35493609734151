.headerContainerDark {
  display: flex;
  align-items: center;
  background: #151515;
  justify-content: space-between;
  box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 0 50px 0 64px;
  height: 70px;
  .logoWrapper {
    display: flex;
    align-items: center;
  }
  .headerInner {
    display: flex;
    align-items: center;
    .newUserBtn {
      display: flex;
      align-items: center;
      gap: 0;
    }
    .optionWrapper {
      display: flex;
      color: #202123;
      align-items: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      a {
        padding: 0 13px;
        border-right: 1px solid #e3e3e3;
        color: #202123;
        text-decoration: none;
        white-space: nowrap;
      }
      a:last-child {
        border: none;
      }
    }
    // .optionWrapper2 {
    //   display: flex;
    //   align-items: center;
    //   margin-right: 500px;
    //   color: #202123;
    //   font-size: 16px;
    //   font-style: normal;
    //   font-weight: 400;
    //   line-height: normal;

    //   a {
    //     padding: 0 12px;
    //     border-right: 1px solid #e3e3e3;
    //     color: #202123;
    //     text-decoration: none;
    //   }
    //   a:last-child {
    //     border: none;
    //   }
    // }

    .core-button {
      border-radius: 10px;
      background: var(
        --Linear,
        linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
      );
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
      border: none;
      padding: 10px 20px;
      color: #ffffff;
      font-size: 16px;
      margin-left: 9px;
      width: 140px;
      font-family: Outfit;
      cursor: pointer;
    }
    @media screen and (max-width: 1100px) {
      .core-button {
        border-radius: 5px;
        background: var(
          --Linear,
          linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
        );
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
        border: none;
        height: fit-content;
        color: #ffffff;
        width: fit-content;
        font-size: 12px;
        margin-left: auto;
        padding: 5px;
        width: fit-content;
      }
    }
    .icon {
      margin: 5px 0 0 7px;
      padding: 7px 5px 6px 7px;
      cursor: pointer;
    }
    .icon2 {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      background-color: #ffffff;
      padding: 7px;
      margin-left: 10px;
      border-radius: 50%;
    }
  }
}

@media screen and (max-width: 1300px) {
  .headerContainerDark {
    display: flex;
    align-items: center;
    background: #151515;
    justify-content: space-between;
    box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 0 25px 0 20px;
    height: 70px;
    .logoWrapper {
      display: flex;
      align-items: center;
    }
    .headerInner {
      display: flex;
      align-items: center;
      .newUserBtn {
        display: flex;
        align-items: center;
        gap: 0;
      }
      .optionWrapper {
        display: flex;
        color: #202123;
        align-items: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        a {
          padding: 0 10px;
          border-right: 1px solid #e3e3e3;
          color: #202123;
          text-decoration: none;
          white-space: nowrap;
        }
        a:last-child {
          border: none;
        }
        // .headerContainerDark{
        //   padding: 0px 25px 0px 20px;
        // }
      }
      // .optionWrapper2 {
      //   display: flex;
      //   align-items: center;
      //   margin-right: 500px;
      //   color: #202123;
      //   font-size: 16px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: normal;

      //   a {
      //     padding: 0 12px;
      //     border-right: 1px solid #e3e3e3;
      //     color: #202123;
      //     text-decoration: none;
      //   }
      //   a:last-child {
      //     border: none;
      //   }
      // }

      .core-button {
        border-radius: 10px;
        background: var(
          --Linear,
          linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
        );
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
        border: none;
        padding: 10px 20px;
        color: #ffffff;
        font-size: 16px;
        margin-left: 9px;
        width: 140px;
        font-family: Outfit;
        cursor: pointer;
      }
      .icon {
        margin: 5px 0 0 7px;
        padding: 7px 5px 6px 7px;
        cursor: pointer;
      }
      .icon2 {
        margin: 0 0 0 23px;
        color: #000;
        background-color: #ffffff;
        padding: 7px 7px 7px 7px;
        border-radius: 50%;
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .headerContainerDark {
    padding: 0 10px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #151515;
    box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.05);
    height: 70px;
    .logoWrapper {
      display: flex;
      align-items: center;
      margin-top: 0;
      padding: 0;
      img {
        margin: 4px 0 0 0;
        height: 23px;
        
      }
    }

    .headerInner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .newUserBtn {
        display: flex;
        align-items: center;
        gap: 0;
      }
      .optionWrapper {
        display: flex;
        color: #202123;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        a {
          padding: 0 8px;
          border-right: 1px solid #e3e3e3;
          color: #202123;
          text-decoration: none;
          white-space: nowrap;
        }
        a:last-child {
          border: none;
        }
      }

      .core-button {
        border-radius: 5px;
        background: var(
          --Linear,
          linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
        );
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
        border: none;
        height: fit-content;
        color: #ffffff;
        width: fit-content;
        font-size: 12px;
        margin-left: auto;
        padding: 5px;
        width: fit-content;
      }
      .icon {
        // margin: auto 10px;
        cursor: pointer;
      }
      .icon2 {
        margin: 10px 0 10px 10px;
        color: #000;
        background-color: #ffffff;
        font-size: 12px;
        padding: 7px 7px 7px 7px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .headerContainerDark{
    .core-button{
      font-size: 7px !important;
    }
    .logoWrapper {
      display: flex;
      align-items: center;
      margin-top: 0;
      padding: 0;
      img {
        margin: 4px 0 0 -15px !important;
        height: 20px;
        
      }
      
    }
  
  }
   

    

  
}

