.btn {
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  text-align: center;
  text-decoration: none;
}

.button {
  -webkit-border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
@-webkit-keyframes glowing {
  0% {
    background-color: #ff721b;
    -webkit-box-shadow: 0 0 3px #ff721b;
  }
  50% {
    background-color: #ff721b;
    -webkit-box-shadow: 0 0 40px #ff721b;
  }
  100% {
    background-color: #ff721b;
    -webkit-box-shadow: 0 0 3px #ff721b;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #ff721b;
    -moz-box-shadow: 0 0 3px #ff721b;
  }
  50% {
    background-color: #ff721b;
    -moz-box-shadow: 0 0 40px #ff721b;
  }
  100% {
    background-color: #ff721b;
    -moz-box-shadow: 0 0 3px #ff721b;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #ff721b;
    box-shadow: 0 0 3px #ff721b;
  }
  50% {
    background-color: #ff721b;
    box-shadow: 0 0 40px #ff721b;
  }
  100% {
    background-color: #ff721b;
    box-shadow: 0 0 3px #ff721b;
  }
}

@keyframes glowing {
  0% {
    background-color: #ff721b;
    box-shadow: 0 0 3px #ff721b;
  }
  50% {
    background-color: #ff721b;
    box-shadow: 0 0 40px #ff721b;
  }
  100% {
    background-color: #ff721b;
    box-shadow: 0 0 3px #ff721b;
  }
}
