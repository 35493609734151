.headerwrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
.childrenWrapper {
  margin-top: 72px;
}
.learningLayout {
  .banner {
    padding: 0 0 0 70px;
    display: block;
    img {
      // height: 77vh;
      // width: 100%;
    }
  }
}
@media screen and (max-width: 600px) {
  .childrenWrapper {
    margin-top: 46px;
  }
  .learningLayout {
    .banner {
      display: none;
    }
  }
}
