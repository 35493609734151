.discoveryWrapper {
  width: 90%;
  margin: auto;
  padding-top: 1rem;
}
.discoveryWrapper .header h2 {
  color: #161e29;
  font-size: 45px;
  font-weight: 600;
  margin: 0;
}
.discoveryWrapper .header p {
  color: rgba(32, 33, 35, 0.6);
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.discoveryWrapper .cards,
.discoveryWrapper .versionCards {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 40px 0 0 0;
  padding: 0 0 7% 0;
}
.discoveryWrapper .cards .card,
.discoveryWrapper .versionCards .card {
  width: 349px;
  height: 220px;
  border-radius: 20px;
  background: #f6f6f6;
  border: 1px solid #e46703;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.discoveryWrapper .cards .card .inner1,
.discoveryWrapper .versionCards .card .inner1 {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.discoveryWrapper .cards .card .inner1 span,
.discoveryWrapper .versionCards .card .inner1 span {
  height: 2rem;
  border-radius: 10px;
  border: 3px solid #523168;
}
.discoveryWrapper .cards .card .inner1 p,
.discoveryWrapper .versionCards .card .inner1 p {
  color: #202123;
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 25px;
  margin: 0;
}
.discoveryWrapper .cards .card .inner2,
.discoveryWrapper .versionCards .card .inner2 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: auto 0 1.5rem 0;
}
.discoveryWrapper .cards .card .inner2 div,
.discoveryWrapper .versionCards .card .inner2 div {
  background-color: rgba(233, 127, 41, 0.4);
  width: 100%;
  border-radius: 10px;
}
.discoveryWrapper .cards .card .inner2 div span,
.discoveryWrapper .versionCards .card .inner2 div span {
  padding: 5px;
  width: 50%;
  display: block;
  border-radius: 10px;
  background-color: #e46703;
}
.discoveryWrapper .cards .card .inner2 p,
.discoveryWrapper .versionCards .card .inner2 p {
  color: rgba(32, 33, 35, 0.75);
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
.discoveryWrapper .cards .card .inner3,
.discoveryWrapper .versionCards .card .inner3 {
  display: flex;
  gap: 20px;
  align-items: center;
}
.discoveryWrapper .cards .card .inner3 button,
.discoveryWrapper .versionCards .card .inner3 button {
  border: none;
  width: 100%;
  padding: 12.853px 20px;
  border-radius: 7.909px;
  border: 1.483px solid #2b1053;
  border-radius: 10px;
  background: var(--Linear, linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%));
  border-color: #e46703;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 795px) {
  .discoveryContainer {
    width: 100%;
    margin: auto;
    padding-top: 2rem;
  }
  .discoveryContainer .header h2 {
    color: #161e29;
    font-size: 25px;
    font-weight: 600;
  }
  .discoveryContainer .header p {
    color: rgba(32, 33, 35, 0.6);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
  .discoveryContainer .cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin: 20px 0 0 0;
  }
  .discoveryContainer .cards .card {
    width: 100%;
    height: 220px;
    border-radius: 20px;
    background: rgba(43, 16, 83, 0.1);
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
  }
  .discoveryContainer .cards .card .inner1 {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .discoveryContainer .cards .card .inner1 p {
    color: #202123;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 25px;
    margin: 0;
  }
  .discoveryContainer .cards .card .inner2 {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .discoveryContainer .cards .card .inner2 div {
    background-color: rgba(43, 16, 83, 0.25);
    width: 100%;
    border-radius: 10px;
  }
  .discoveryContainer .cards .card .inner2 div span {
    padding: 5px;
    width: 50%;
    display: block;
    border-radius: 10px;
    background-color: #2b1053;
  }
  .discoveryContainer .cards .card .inner2 p {
    color: rgba(32, 33, 35, 0.75);
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }
  .discoveryContainer .cards .card .inner3 {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .discoveryContainer .cards .card .inner3 button {
    border: none;
    width: 100%;
    padding: 12.853px 29.66px;
    border-radius: 7.909px;
    background-color: transparent;
    border: 1.483px solid #2b1053;
    cursor: pointer;
  }
}/*# sourceMappingURL=discoveryCoaches.css.map */