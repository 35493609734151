.fundersListContainer {
  .introSection {
    background-image: url("../../../../public/images/funderimg.png");
    height: 500px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    h1 {
      color: #fff;
      font-size: 60px;
      font-weight: 700;
      letter-spacing: 1.2px;
      margin: 0;
    }
    p {
      color: #fff;
      font-size: 30px;
      font-weight: 400;
      letter-spacing: 0.6px;
      margin: 0;
    }
  }
  .percentageWrapper {
    padding-top: 40px;
    width: 80%;
    margin: auto;
  }
  .listWrapper {
    background-color: #f8f8f8;
    padding: 30px 20px;
    margin-top: 20px;
    width: 80%;
    margin: auto;
    .resourceHeading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .optionWrapper {
      display: flex;
      // margin: 0 auto auto auto;
      width: 582px;
      height: 71px;
      padding: 8px;
      align-items: center;
      justify-content: space-around;
      border-radius: 20px;
      border: 1.5px solid #523168;
      div {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        color: #523168;
        font-size: 18px;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    h2 {
      color: #161e29;
      font-size: 40px;
      font-weight: 600;
    }
    .fundersList {
      width: 100%;
      display: flex;
      gap: 20px;
      flex-direction: column;
      max-height: 500px;
      overflow-y: scroll;
      .funder {
        // height: 295px;
        position: relative;
        gap: 30px;
        display: flex;
        align-items: center;
        border-radius: 15px;
        border: 1px solid rgba(82, 49, 104, 0.25);
        background: #fff;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
        position: relative;
        padding: 20px;
        .bookmark {
          background-color: lightgray;
          padding: 5px 6px 0 6px;
          border-radius: 20px;
          cursor: pointer;
          position: absolute;
          right: 20px;
          top: 15px;
        }
        img {
          width: 209px;
          height: 252px;
        }
        .funderDetails {
          width: 100%;
          .companyLocation {
            font-size: 14px;
            font-weight: 400;
            margin-top: 6px;
          }
          .companyName {
            h2 {
              color: #202123;
              margin: 0;
              font-size: 24px;
              font-weight: 600;
              letter-spacing: 0.48px;
            }
            .location {
              display: flex;
              align-items: center;
              margin: 9px 0 18px 0;
              span {
                color: #202123;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.28px;
              }
            }
          }
          .applicationDetails {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 8px;

            .prize {
              margin-bottom: 0px;
            }
            h3 {
              margin: 0;
              color: #080515;
              font-size: 18px;
              font-weight: 500;
            }
            p {
              margin: 0;
              color: #202123;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0.28px;
            }
          }
          .eligibility {
            margin-top: 12px;
            // width: 80%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            h4 {
              margin: 0;
              color: #202123;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0.28px;
            }
            p {
              margin: 0;
              color: #080515;
              font-size: 13px;
              font-weight: 400;
              line-height: 130%;
              padding: 0 10px 0 0;
            }
            button {
              all: unset;
              border-radius: 7.045px;
              background: var(
                --Linear,
                linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
              );
              box-shadow: 0px 2.818px 10.567px 0px rgba(0, 0, 0, 0.25);
              color: #fff;
              display: flex;
              width: 144px;
              height: 50px;
              justify-content: center;
              align-items: center;
              // position: absolute;
              // right: 20px;
              // bottom: 31px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .fundersListContainer {
    .introSection {
      background-image: url("../../../../public/images/funderimg.png");
      height: 350px;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      h1 {
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        letter-spacing: 1.2px;
        margin: 0;
      }
      p {
        color: #fff;
        font-size: 22px;
        font-weight: 400;
        letter-spacing: 0.6px;
        margin: 0;
      }
    }
    .percentageWrapper {
      padding-top: 40px;
      width: 80%;
      margin: auto;
    }
    .listWrapper {
      background-color: #f8f8f8;
      padding: 30px 20px;
      margin-top: 20px;
      width: 80%;
      margin: auto;
      .resourceHeading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .optionWrapper {
        display: flex;
        margin: 0 auto auto auto;
        width: 582px;
        height: 71px;
        padding: 8px;
        align-items: center;
        justify-content: space-around;
        border-radius: 20px;
        border: 1.5px solid #523168;
        div {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          display: flex;
          color: #523168;
          font-size: 18px;
          font-weight: 500;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
      h2 {
        color: #161e29;
        font-size: 30px;
        font-weight: 600;
      }
      .fundersList {
        width: 100%;
        display: flex;
        gap: 20px;
        flex-direction: column;
        max-height: 500px;
        overflow-y: scroll;
        .funder {
          // height: 295px;
          position: relative;
          gap: 30px;
          display: flex;
          align-items: center;
          border-radius: 15px;
          border: 1px solid rgba(82, 49, 104, 0.25);
          background: #fff;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
          position: relative;
          padding: 20px;
          .bookmark {
            background-color: lightgray;
            padding: 5px 6px 0 6px;
            border-radius: 20px;
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 15px;
          }
          img {
            width: 209px;
            height: 252px;
          }
          .funderDetails {
            width: 100%;
            .companyLocation {
              font-size: 14px;
              font-weight: 400;
              margin-top: 6px;
            }
            .companyName {
              h2 {
                color: #202123;
                margin: 0;
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 0.48px;
              }
              .location {
                display: flex;
                align-items: center;
                margin: 9px 0 18px 0;
                span {
                  color: #202123;
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: 0.28px;
                }
              }
            }
            .applicationDetails {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 8px;

              .prize {
                margin-bottom: 0px;
              }
              h3 {
                margin: 0;
                color: #080515;
                font-size: 18px;
                font-weight: 500;
              }
              p {
                margin: 0;
                color: #202123;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
              }
            }
            .eligibility {
              margin-top: 12px;
              // width: 80%;
              h4 {
                margin: 0;
                color: #202123;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
              }
              p {
                margin: 0;
                color: #080515;
                font-size: 13px;
                font-weight: 400;
                line-height: 130%;
                padding: 0 10px 0 0;
              }
            }
            button {
              all: unset;
              border-radius: 7.045px;
              background: var(
                --Linear,
                linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
              );
              box-shadow: 0px 2.818px 10.567px 0px rgba(0, 0, 0, 0.25);
              color: #fff;
              display: flex;
              width: 144px;
              height: 50px;
              justify-content: center;
              align-items: center;
              position: absolute;
              right: 20px;
              bottom: 31px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .fundersListContainer {
    .introSection {
      background-image: url("../../../../public/images/funderimg.png");
      height: 250px;
      width: 100%;
      padding: 5%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      h1 {
        color: #fff;
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 1.2px;
        margin: 0;
      }
      p {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.6px;
        margin: 0;
      }
    }
    .percentageWrapper {
      padding-top: 20px;
      width: 80%;
      margin: auto;
    }
    .listWrapper {
      background-color: #f8f8f8;
      padding: 30px 20px;
      margin-top: 20px;
      width: 90%;
      margin: auto;
      .resourceHeading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .optionWrapper {
        display: flex;
        margin: 0 auto auto auto;
        width: 302px;
        height: 71px;
        padding: 8px;
        align-items: center;
        justify-content: space-around;
        border-radius: 20px;
        border: 1.5px solid #523168;
        div {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          display: flex;
          color: #523168;
          font-size: 18px;
          font-weight: 500;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
      h2 {
        color: #161e29;
        font-size: 24px;
        font-weight: 600;
      }
      .fundersList {
        width: 100%;
        display: flex;
        gap: 20px;
        flex-direction: column;
        max-height: 500px;
        overflow-y: scroll;
        .funder {
          // height: 295px;
          position: relative;
          gap: 30px;
          display: flex;
          align-items: center;
          border-radius: 15px;
          border: 1px solid rgba(82, 49, 104, 0.25);
          background: #fff;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
          position: relative;
          padding: 20px;
          .bookmark {
            background-color: lightgray;
            padding: 5px 6px 0 6px;
            border-radius: 20px;
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 15px;
          }
          img {
            width: 209px;
            height: 252px;
          }
          .funderDetails {
            width: 100%;
            .companyLocation {
              font-size: 14px;
              font-weight: 400;
              margin-top: 6px;
            }
            .companyName {
              h2 {
                color: #202123;
                margin: 0;
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 0.48px;
              }
              .location {
                display: flex;
                align-items: center;
                margin: 9px 0 18px 0;
                span {
                  color: #202123;
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: 0.28px;
                }
              }
            }
            .applicationDetails {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 8px;

              .prize {
                margin-bottom: 0px;
              }
              h3 {
                margin: 0;
                color: #080515;
                font-size: 18px;
                font-weight: 500;
              }
              p {
                margin: 0;
                color: #202123;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
              }
            }
            .eligibility {
              margin-top: 12px;
              // width: 80%;
              h4 {
                margin: 0;
                color: #202123;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
              }
              p {
                margin: 0;
                color: #080515;
                font-size: 13px;
                font-weight: 400;
                line-height: 130%;
                padding: 0 10px 0 0;
              }
            }
            button {
              all: unset;
              border-radius: 7.045px;
              background: var(
                --Linear,
                linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
              );
              box-shadow: 0px 2.818px 10.567px 0px rgba(0, 0, 0, 0.25);
              color: #fff;
              display: flex;
              width: 144px;
              height: 50px;
              justify-content: center;
              align-items: center;
              position: absolute;
              right: 20px;
              bottom: 31px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .fundersListContainer {
    .introSection {
      background-image: url("../../../../public/images/funderimg.png");
      height: 200px;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      h1 {
        color: #fff;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 1.2px;
        margin: 0;
      }
      p {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.6px;
        margin: 0;
      }
    }
    .percentageWrapper {
      padding-top: 40px;
      width: 80%;
      margin: auto;
    }
    .listWrapper {
      background-color: #f8f8f8;
      padding: 30px 20px;
      margin-top: 20px;
      width: 80%;
      margin: auto;
      .resourceHeading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .optionWrapper {
        display: flex;
        margin: 0 auto auto auto;
        max-width: 582px;
        height: 71px;
        padding: 8px;
        align-items: center;
        justify-content: space-around;
        border-radius: 20px;
        border: 1.5px solid #523168;
        div {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          display: flex;
          color: #523168;
          font-size: 18px;
          font-weight: 500;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
      h2 {
        color: #161e29;
        font-size: 20px;
        font-weight: 600;
      }
      .fundersList {
        width: 100%;
        display: flex;
        gap: 20px;
        flex-direction: column;
        max-height: 500px;
        overflow-y: scroll;
        .funder {
          // height: 295px;
          position: relative;
          gap: 30px;
          display: flex;
          align-items: center;
          border-radius: 15px;
          border: 1px solid rgba(82, 49, 104, 0.25);
          background: #fff;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
          position: relative;
          padding: 20px;
          .bookmark {
            background-color: lightgray;
            padding: 5px 6px 0 6px;
            border-radius: 20px;
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 15px;
          }
          img {
            width: 209px;
            height: 252px;
          }
          .funderDetails {
            width: 100%;
            .companyLocation {
              font-size: 14px;
              font-weight: 400;
              margin-top: 6px;
            }
            .companyName {
              h2 {
                color: #202123;
                margin: 0;
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 0.48px;
              }
              .location {
                display: flex;
                align-items: center;
                margin: 9px 0 18px 0;
                span {
                  color: #202123;
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: 0.28px;
                }
              }
            }
            .applicationDetails {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 8px;

              .prize {
                margin-bottom: 0px;
              }
              h3 {
                margin: 0;
                color: #080515;
                font-size: 18px;
                font-weight: 500;
              }
              p {
                margin: 0;
                color: #202123;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
              }
            }
            .eligibility {
              margin-top: 12px;
              // width: 80%;
              h4 {
                margin: 0;
                color: #202123;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
              }
              p {
                margin: 0;
                color: #080515;
                font-size: 13px;
                font-weight: 400;
                line-height: 130%;
                padding: 0 10px 0 0;
              }
            }
            button {
              all: unset;
              border-radius: 7.045px;
              background: var(
                --Linear,
                linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
              );
              box-shadow: 0px 2.818px 10.567px 0px rgba(0, 0, 0, 0.25);
              color: #fff;
              display: flex;
              width: 144px;
              height: 50px;
              justify-content: center;
              align-items: center;
              position: absolute;
              right: 20px;
              bottom: 31px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
