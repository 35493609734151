.btnWrapper {
  display: flex;
  gap: 15px;
  align-items: center;
  button {
    border: none;
    padding: 12px 30px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 10px;
    cursor: pointer;
  }
  .saveBtn {
    color: #161e29;

    background: #e0e0e0;
  }
  .previousBtn {
    border: 1px solid var(--a, #523168);
    color: #523168;
  }
  .nextBtn {
    color: #fff;
    padding: 12px 50px !important;
    margin-right: 20px;
    background: var(
      --Linear,
      linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
    );
  }
}
.react-multiple-carousel__arrow--right {
  z-index: 9 !important;
  background: var(
    --Linear,
    linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
  ) !important;
}
.react-multiple-carousel__arrow--left {
  z-index: 9 !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/* Adjust the color as needed */
//  .react-tel-input , .form-control{
//     width: 100%;
//         max-width: 500px,
//  }

.grid {
  @media screen and (max-width: 1024px) {
    .MuiGrid-item {
      padding: 0px;
    }
  }
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.displayStart {
  display: flex;
  align-items: center;
  justify-content: start;
}
.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.displayColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
* {
  scrollbar-width: 0px;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-moz-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}
