.titleWrapper {
  // background: linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%);
  background-image: url("../../../public/discovery/percentageFrame.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 0px 30px;
  h2 {
    font-size: 40px;
    font-style: normal;
    margin: 0;
    font-weight: 600;
    line-height: normal;
  }
}
.discoveryContainer {
  width: 75%;
  margin: auto;
  padding-top: 20px;

  h2 {
    color: #161e29;
    font-size: 40px;
    font-style: normal;
    margin: 0;
    font-weight: 600;
    line-height: normal;
  }

  .stepperWrapper {
    display: flex;
    margin-top: 5rem;
    justify-content: space-between;
    // align-items: center;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -2rem;

      h3 {
        color: #161e29;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        margin: 0;
      }
      button {
        border: none;
        color: #fff;
        font-size: 15px;
        padding: 10px 15px;
        cursor: pointer;
        border-radius: 10px;
        background: var(
          --Linear,
          linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
        );
      }
    }
  }

  .btnContainer {
    margin: 30px 0 7rem 0;
    display: flex;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 600px) {
  .discoveryContainer {
    width: 90%;
    margin: auto;
    padding-top: 20px;

    h2 {
      color: #161e29;
      font-size: 22px;
      font-style: normal;
      margin: 0;
      font-weight: 600;
      line-height: normal;
    }

    .stepperWrapper {
      display: flex;
      margin-top: 5rem;
      justify-content: space-between;
      // align-items: center;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -2rem;

        h3 {
          color: #161e29;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          margin: 0;
        }
        button {
          border: none;
          color: #fff;
          font-size: 15px;
          padding: 10px 10px;
          cursor: pointer;
          border-radius: 10px;
          background: var(
            --Linear,
            linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
          );
        }
      }
    }

    .btnContainer {
      width: 90%;
      margin: 30px 10px 7rem 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}
