.profileWrapper {
  display: flex;
  gap: 10px;
  padding: 10px 20px;
  .card-container {
    display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
    padding: 0 20px;
    flex-direction: row;
    margin-top: 35px;

    .cards {
      padding: 30px 20px;
      flex-shrink: 0;
      border-radius: 20px;
      border: 3px solid #fff;
      background: var(
        --Linear,
        linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
      );
      box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.27);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 5vw;
      }
      div {
        color: #fff;
        margin-top: 30px;
        width: 20vw;
        text-align: center;
        font-size: 1.5vw;
        font-weight: 700;
        letter-spacing: 0.44px;
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .profileWrapper {
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    .card-container {
      display: flex;
      justify-content: center;
      gap: 25px;
      flex-wrap: wrap;
      padding: 0 20px;
      flex-direction: row;
      margin-top: 35px;

      .cards {
        padding: 30px 20px;
        flex-shrink: 0;
        border-radius: 20px;
        border: 3px solid #fff;
        background: var(
          --Linear,
          linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
        );
        box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.27);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 5vw;
        }
        div {
          color: #fff;
          margin-top: 30px;
          width: 20vw;
          text-align: center;
          font-size: 15px;
          font-weight: 700;
          letter-spacing: 0.44px;
          text-transform: uppercase;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .profileWrapper {
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    .card-container {
      display: flex;
      justify-content: center;
      gap: 25px;
      flex-wrap: wrap;
      padding: 0 20px;
      flex-direction: row;
      margin-top: 35px;

      .cards {
        padding: 30px 20px;
        flex-shrink: 0;
        border-radius: 20px;
        border: 3px solid #fff;
        background: var(
          --Linear,
          linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
        );
        box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.27);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 5vw;
        }
        div {
          color: #fff;
          margin-top: 5px;
          width: 30vw;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.44px;
          text-transform: uppercase;
        }
      }
    }
  }
}
.pagination {
  .MuiPaginationItem-root.Mui-selected {
    background-color: #523168;
    color: #fff;
  }
  .css-1bnjdmz-MuiButtonBase-root-MuiPaginationItem-root {
    color: #000;
  }
  .css-1iyk3c7-MuiPaginationItem-root {
    color: #000;
  }
}
.pagination-dark {
  .MuiPaginationItem-root.Mui-selected {
    background-color: #523168;
    color: #fff;
  }
  .css-1bnjdmz-MuiButtonBase-root-MuiPaginationItem-root {
    color: #fff;
  }
  .css-1iyk3c7-MuiPaginationItem-root {
    color: #fff;
  }
}
.pagination:hover {
  .MuiPaginationItem-root.Mui-selected {
    background-color: #523168;
    color: #fff;
  }
}
.paginationBtn {
  background-color: #523168;
  color: #fff;
}
.paginationBtn:hover {
  .MuiButtonBase-root-MuiButton-root {
    background-color: #fff;
    color: #523168;
  }
}
