.main-wrapper {
  // padding: 3% 0% 5% 0%;
  .first-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .main-heading {
      font-size: 30px;
      font-weight: 600;
    }
  }
  .second-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    padding: 20px;
    border-radius: 10px;
    width: 100%;

    .inner-1 {
      display: flex;
      gap: 20px;
      align-items: center;
      width: 370px;
      .letter-div {
        width: 70px;
        height: 70px;
        background-color: #523168;
        border-radius: 50%;
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 400;
      }
      .inner1-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        h2 {
          margin: 0;
          color: #262626;
          font-family: Outfit;
          font-size: 18px;
          font-weight: 600;
        }
        div {
          display: flex;
          margin-top: 12px;
          gap: 40px;
          color: rgba(8, 5, 21, 0.7);
        }
      }
    }
    .inner-3 {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 30px;
      width: 350px;

      .inner3-container {
        display: flex;
        flex-direction: column;
        gap: 18px;
        .inner3-span {
          color: rgba(8, 5, 21, 0.7);
        }
      }
      .inner3-B {
        color: #262626;

        font-family: Outfit;
        font-size: 30px;
        font-weight: 400;
      }
    }
  }
  .pagination-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 65px;
  }
}

@media screen and (max-width: 500px) {
  .main-wrapper {
    // padding: 3% 10% 5% 10%;
    .first-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      .main-heading {
        font-size: 22px;
      }
    }
    .second-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      padding: 20px;
      border-radius: 10px;
      width: 100%;

      .inner-1 {
        display: flex;
        gap: 20px;
        align-items: center;
        width: 260px;
        .letter-div {
          width: 40px;
          height: 40px;
          background-color: #523168;
          border-radius: 50%;
          color: rgb(255, 255, 255);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: 400;
        }
        .inner1-container {
          h2 {
            margin: 0;
            color: #262626;
            font-family: Outfit;
            font-size: 18px;
            font-weight: 600;
          }
          div {
            display: flex;
            margin-top: 12px;
            gap: 40px;
            color: rgba(8, 5, 21, 0.7);
          }
        }
      }
      .inner-3 {
        display: flex;
        align-items: center;
        gap: 30px;
        width: 200px;

        .inner3-container {
          display: flex;
          flex-direction: column;
          gap: 18px;
          .inner3-span {
            color: rgba(8, 5, 21, 0.7);
          }
        }
        .inner3-B {
          color: #262626;

          font-family: Outfit;
          font-size: 30px;
          font-weight: 400;
        }
      }
    }
    .pagination-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 65px;
    }
  }
}
