.main-wrapper {
  // padding: 3% 8% 5% 8%;
  .first-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .second-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inner1 {
      display: flex;
      gap: 25px;
      align-items: center;
      width: 350px;
      .B-div {
        color: #262626;
        width: 70px;
        height: 70px;
        background-color: #c1c1c1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 400;
      }
    }

    .inner2 {
      .inner2-first {
        color: #262626;
        font-family: Outfit;
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin-top: 10px;
      }
    }
    .inner3 {
      display: flex;
      flex-direction: column;
      gap: 90px;
      .date-div {
        color: var(--Heading, #080515);
        font-family: Outfit;
        font-size: 16px;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
        width: 100px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-wrapper {
    // padding: 3% 10% 5% 10%;
    .first-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .second-container {
      display: flex;
      flex-direction: column;
      gap: 8vw;
      align-items: center;

      .inner1 {
        display: flex;
        gap: 25px;
        align-items: center;

        .B-div {
          color: #262626;
          width: 70px;
          height: 70px;
          background-color: #c1c1c1;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          font-weight: 400;
        }
      }

      .inner2 {
        .inner2-first {
          color: #262626;
          font-family: Outfit;
          font-size: 18px;
          font-weight: 600;
        }

        p {
          margin-top: 10px;
        }
      }
      .inner3 {
        display: flex;
        flex-direction: column;
        gap: 90px;
        .date-div {
          color: var(--Heading, #080515);
          font-family: Outfit;
          font-size: 16px;
          font-weight: 400;
          line-height: 130%; /* 20.8px */
          width: 100px;
        }
      }
    }
  }
}
