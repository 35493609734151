.profile-sidebar {
  margin: 15px 0px;
  height: 87vh;
  //   padding: 0px 20px;
  .hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile-img {
      width: 150px;
      height: 150px;

      border-radius: 150px;
      border: 3px solid #e9e9e9;
      // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
    }

    .owner-name {
      color: #262626;
      font-size: 20px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px 20px;
      width: 290px;
      text-align: center;
    }

    .owner-designation {
      color: #00000099;
      font-family: Outfit;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      margin-top: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      text-align: center;
    }
  }

  .contact-details {
    margin: 40px;
    .contact-item {
      display: flex;
      align-items: center;
      margin: 16px 0px;

      span {
        color: #262626;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .bottom-div {
    margin-top: 45px;

    padding: 0px 20px;
    .give-testimonial {
      width: 284px;
      //   padding: "0px 20px";
      height: 60px;
      display: flex;
      cursor: pointer;

      align-items: center;

      justify-content: center;
      h2 {
        color: #262626;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        margin-left: 22px;
      }
    }
    .logout {
      width: 284px;
      //   padding: "0px 20px";
      height: 60px;
      flex-shrink: 0;
      display: flex;
      margin-top: 20px;
      align-items: center;
      border-radius: 5px;
      border: 1px dashed #ff0909;
      justify-content: center;
      h2 {
        color: #ff1818;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
        margin-left: 15px;
      }
    }
  }
}

.profile-sidebar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.profile-sidebar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
@media screen and (max-width: 900px) {
  .profile-sidebar {
    margin: 15px 0px;
    overflow: hidden;
    //   padding: 0px 20px;

    .hero-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .profile-img {
        width: 150px;
        height: 150px;

        border-radius: 150px;
        border: 3px solid #e9e9e9;
        // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
      }

      .owner-name {
        color: #262626;
        font-size: 20px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px 20px;
        width: 200px;
        text-align: center;
      }

      .owner-designation {
        color: #00000099;
        font-family: Outfit;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin-top: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 290px;
        text-align: center;
      }
    }

    .contact-details {
      margin: 40px;
      .contact-item {
        display: flex;
        align-items: center;
        margin: 16px 0px;

        span {
          color: #262626;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .bottom-div {
      margin-top: 45px;

      padding: 0px 20px;
      .give-testimonial {
        width: 200px;
        //   padding: "0px 20px";
        height: 60px;
        display: flex;
        cursor: pointer;

        align-items: center;
        border: 1px dashed #000;
        justify-content: center;
        h2 {
          color: #262626;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          margin-left: 22px;
        }
      }
      .logout {
        width: 200px;
        //   padding: "0px 20px";
        height: 60px;
        flex-shrink: 0;
        display: flex;
        margin-top: 20px;
        align-items: center;
        border-radius: 5px;
        border: 1px dashed #ff0909;
        justify-content: center;
        h2 {
          color: #ff1818;
          font-size: 18px;
          font-weight: 400;
          text-transform: uppercase;
          margin-left: 15px;
        }
      }
    }
  }
}
