.learningLivesContainer {
  // padding: 0 10%;
  .header {
    .optionWrapper {
      display: flex;
      margin: 0 auto auto auto;
      width: 582px;
      height: 71px;
      padding: 8px;
      align-items: center;
      justify-content: space-around;
      border-radius: 20px;
      background: linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%);
      div {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        font-size: 18px;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
      }
    }
  }
  .courses {
    .course {
      color: #fff;
      border-radius: 20px;
      background: #523168;
      img {
        width: 383px;
        height: 273px;
      }
      h3 {
        img {
          width: 28px;
          height: 20px;
        }
        margin: 20px 0 0 0;
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 25px;
        font-weight: 500;
      }
      p {
        color: rgba(255, 255, 255, 0.75);
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;
        margin: 15px 0;
      }
      div {
        display: flex;
        justify-content: space-between;
      }
      button {
        border-radius: 10px;
        background: var(
          --Linear,
          linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
        );
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
        border: none;
        padding: 10px 20px;
        color: #fff;
        font-size: 16px;
        font-family: Outfit;
        width: 246px;
        margin: 17px auto auto auto;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1620px) {
  .learningLivesContainer {
    .courses {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 18px;
      margin-top: 0rem;
      .course {
        padding: 19px 18px;
        max-width: 419px;
        display: grid;
        color: #fff;
        border-radius: 10px;
        background: #523168;
        img {
          width: 383px;
          height: 273px;
        }
        h3 {
          img {
            width: 28px;
            height: 20px;
          }
          margin: 20px 0 0 0;
          display: flex;
          align-items: center;
          gap: 20px;
          font-size: 25px;
          font-weight: 500;
        }
        p {
          color: rgba(255, 255, 255, 0.75);
          font-size: 16px;
          font-weight: 400;
          text-transform: capitalize;
          margin: 15px 0;
        }
        div {
          display: flex;
          justify-content: space-between;
        }
        button {
          border-radius: 10px;
          background: var(
            --Linear,
            linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
          );
          box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
          border: none;
          padding: 10px 20px;
          color: #fff;
          font-size: 16px;
          font-family: Outfit;
          width: 246px;
          margin: 17px auto auto auto;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .learningLivesContainer {
    .courses {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 18px;
      margin-top: 2rem;
      .course {
        padding: auto;
        max-width: auto;
        display: grid;
        color: #fff;
        border-radius: 10px;
        background: #523168;
        img {
          width: 100%;
          height: 273px;
        }
        h3 {
          img {
            width: 28px;
            height: 20px;
          }
          margin: 20px 0 0 0;
          display: flex;
          align-items: center;
          gap: 20px;
          font-size: 25px;
          font-weight: 500;
        }
        p {
          color: rgba(255, 255, 255, 0.75);
          font-size: 16px;
          font-weight: 400;
          text-transform: capitalize;
          margin: 15px 0;
        }
        div {
          display: flex;
          justify-content: space-between;
        }
        button {
          border-radius: 10px;
          background: var(
            --Linear,
            linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
          );
          box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
          border: none;
          padding: 10px 20px;
          color: #fff;
          font-size: 16px;
          font-family: Outfit;
          width: 246px;
          margin: 17px auto auto auto;
          cursor: pointer;
        }
      }
    }
    .header {
      .optionWrapper {
        width: 100%;
        height: 51px;
        padding: 8px;
        div {
          width: 50%;
          height: 100%;
          font-size: 14px;
        }
      }
    }
  }
}
