.stepperCotainer {
  display: flex;
  flex-direction: column;
  .stepperWrapper {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .circle {
      position: relative;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      margin: 2px 0;
      background: #523168;
      transition: background-color 0.3s ease-in-out;
      span {
        position: absolute;
        left: 30px;
        display: block;
        width: 280px;
        color: rgba(22, 30, 41, 0.75);
        bottom: 1px;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
    .line {
      height: 40px;
      width: 0;
      margin: auto;
      border: 1px solid #523168;
      transition: border-color 0.3s ease-in-out;
    }
  }
}

@media screen and (max-width: 768px) {
  .stepperCotainer {
    display: none;
  }
}
