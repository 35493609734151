.phoneInput {
  .react-tel-input,
  .form-control {
    height: 53px;
    width: 100%;
    // max-width: 688px ;
  }
  @media screen and (max-width: 1200px) {
    .react-tel-input,
    .form-control {
      width: 100%;
      max-width: 100%;
      // margin: "10px 0px",
    }
  }
  @media screen and (max-width: 1024px) {
    .react-tel-input,
    .form-control {
      width: 70vw !important;
      max-width: 70vw;
      // margin: "10px 0px",
    }
  }
}

.MuiFormControl-root-MuiTextField-root{
  height: 15vh !important;
}
