 .mobilefield {

     .react-tel-input,
     .form-control {
         margin-top: 20px;
         height: 56px !important;
         width: 100%;
         max-width: 500px;

     }

     @media screen and (max-width:600px) {

         .react-tel-input,
         .form-control {
             height: 56px;
             max-width: 100% // margin: "10px 0px",
         }
     }

 }