.react-multi-carousel-dot-list {
  margin: 15px 0 10px 0 !important;
  .react-multi-carousel-dot--active button {
    background: #fff !important;
  }
  button {
    background: transparent !important;
    border-color: #fff !important;
  }
}

// .key-description::after {
//   content: "...";
//   display: block;
//   white-space: nowrap; /* Prevent the ellipsis from wrapping to a new line */
//   overflow: hidden; /* Hide any content overflowing the container */
// }
.heroBannerContainer {
  width: 100%;
  height: 750px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  flex-direction: column;

  .inner1 {
    display: flex;
    align-items: center;
    padding: 80px 0 0 50px;
    position: relative;

    .text-container {
      padding: 10px;
    }
    h2 {
      margin-bottom: 0;
      font-size: 4vw;
      font-style: normal;
      width: 100%;
      font-weight: 700;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; // line-height: 134%;
      letter-spacing: 1.4px;
      text-transform: capitalize;
      // span {
      //   // margin-top: 20px;
      //   // background: var(
      //   //   --Linear,
      //   //   linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
      //   // );
      //   // background-clip: text;
      //   // -webkit-background-clip: text;
      //   // -webkit-text-fill-color: transparent;
      // }
    }
    p {
      margin: 0;
      color: rgba(255, 255, 255, 0.75);
      font-family: Outfit;
      font-size: 20px;
      font-style: normal;
      width: 56%;
      margin-bottom: 20px;
      font-weight: 400;
      line-height: 139%; /* 27.8px */
      letter-spacing: 0.4px;
    }
    button {
      position: absolute;
      height: fit-content;
      right: 300px;
      top: 300px;
      border: none;
      border-radius: 11.62px;
      background: var(
        --Linear,
        linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
      );
      color: #fff;
      width: 270px;
      gap: 2rem;
      padding: 20px 30px;
      display: flex;
      font-size: 1.2em;
      cursor: pointer;
      font-weight: 600;
      justify-content: center;
      align-items: center;
    }
  }
  .inner2 {
    display: flex;
    align-items: center;
    margin: 200px 0 0 30px;
    gap: 0;
    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: fit-content;
      h3 {
        color: #fff;
        text-align: center;
        font-family: Outfit;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        margin: 0;
      }
      p {
        color: rgba(255, 255, 255, 0.87);
        text-align: center;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        width: 60%;
        margin: 0;
      }
    }
  }
}

// .swiper-button-next {
//   color: #fff;
//   display: block;
//   background-color: #c7340d;
//   border-radius: 50%;
// }
// .swiper-button-next:after {
//   content: "next";
//   font-size: 12px !important;
// }
.swiper-button-prev,
.swiper-button-next {
  background-color: #d34709;
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);

  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 50%;
  justify-content: center;
}
// .swiper-button-prev:hover,
// .swiper-button-next:hover {
//   background-color: #ffffff;
//   position: absolute;
//   top: var(--swiper-navigation-top-offset, 50%);

//   margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
//   z-index: 10;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   padding: 20px;
//   border-radius: 50%;
//   justify-content: center;
// }

.collaborativePartnerContainer {
  padding: 0 3%;
  .custom-next-button,
  .custom-prev-button {
    /* Add your styles here */
    background-color: #ff0000; /* Example background color */
    color: #ffffff; /* Example text color */
    /* Add any other styles you want to customize */
  }
  .collaborativePartnerBox {
    position: relative;
    padding: 20px 40px;
    border: 1px solid #dcdcdc;
    margin: 0 1rem;
    border-radius: 10px 10px 10px 0;
    height: 340px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    .vextorImage {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
.collaborativeProjectContainer {
  position: relative;
  // height: 60vh;
  padding: 0 3%;
  .bgVector {
    position: absolute;
    left: 0;
    width: 100%;
    height: 40vh;
    bottom: -3rem;
  }
  .collaborativeProjectWrapper {
    background-color: #523168;
    padding: 15px 0 0 0;
    // width: 419px;
    margin: 2.1rem 10px 0 10px;
    height: 460px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: "0px 10px";
    .img-wrapper {
      width: "100%";
      display: flex;
      justify-content: center;
      align-items: center;
      .hero-img {
        width: 90%;
        // height: ;

        border-radius: 10px;
      }
    }
  }
}
.testimonialCardsContainer {
  position: relative;
  .testimonialHeader {
    text-align: center;
  }
  .arrowBtn {
    position: absolute;
    top: 50%;
  }
  .testimonialCardsWrapper {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    justify-content: start;
    // overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 2rem;
    width: 97%;
    margin: auto;
    height: 510px;
    padding: 5% 0 0 1%;
    .testimonialCard {
      height: 310px;
      // width: 400px;
      position: relative;
      padding: 50px 30px 20px 30px;
      border-radius: 15px;
      background-color: #e1e1e1;
      display: flex;
      flex-direction: column;
      transition: height 0.2s ease, width 0.2s ease;
      p {
        margin: auto;
      }
      .profilePic {
        position: absolute;
        height: 70px;
        width: 70px;
        border-radius: 70px;
        top: -40px;
        left: 40%;
        object-fit: cover;
      }
      .cardFooter {
        display: flex;
        justify-content: end;
        margin-top: auto;
        margin-bottom: 10px;
        margin-right: 10px;
        gap: 0.5rem;
      }
    }
  }
  .testimonialCardsWrapper::-webkit-scrollbar {
    display: none;
  }
}
.howItWorksContainer {
  height: 690px;
  position: relative;
  width: 100%;
  background-image: url("../../../public/images/howitwork.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1517px) {
  .howItWorksContainer {
    height: 700px;
    position: relative;
    width: 100%;
    background-image: url("../../../public/images/bigss.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .testimonialCardsContainer {
    position: relative;
    .testimonialHeader {
      text-align: center;
    }
    .arrowBtn {
      position: absolute;
      top: 50%;
    }
    .testimonialCardsWrapper {
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      justify-content: start;
      // overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      gap: 2rem;
      width: 97%;
      margin: auto;
      height: 400px;
      padding: 5% 0 0 2.8%;
      .testimonialCard {
        height: 250px;
        // width: 300px;
        position: relative;
        padding: 50px 30px 20px 30px;
        border-radius: 15px;
        background-color: #e1e1e1;
        display: flex;
        flex-direction: column;
        transition: height 0.2s ease, width 0.2s ease;
        p {
          margin: auto;
        }
        .profilePic {
          position: absolute;
          height: 70px;
          width: 70px;
          border-radius: 70px;
          top: -40px;
          left: 40%;
          object-fit: cover;
        }
        .cardFooter {
          display: flex;
          justify-content: end;
          margin-top: auto;
          margin-bottom: 10px;
          margin-right: 10px;
          gap: 0.5rem;
        }
      }
    }
    .testimonialCardsWrapper::-webkit-scrollbar {
      display: none;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: #d34709;
    position: absolute;
    top: var(--swiper-navigation-top-offset, 40%);

    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 50%;
    justify-content: center;
  }
}

@media screen and (max-width: 1213px) {
  .testimonialCardsContainer {
    position: relative;
    .testimonialHeader {
      text-align: center;
    }
    .arrowBtn {
      position: absolute;
      top: 50%;
    }
    .testimonialCardsWrapper {
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      justify-content: start;
      // overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      gap: 2rem;
      width: 97%;
      margin: auto;
      height: 400px;
      padding: 5% 0 0 2.8%;
      .testimonialCard {
        height: 250px;
        // width: 250px;
        position: relative;
        padding: 50px 30px 20px 30px;
        border-radius: 15px;
        background-color: #e1e1e1;
        display: flex;
        flex-direction: column;
        transition: height 0.2s ease, width 0.2s ease;
        p {
          margin: auto;
        }
        .profilePic {
          position: absolute;
          height: 70px;
          width: 70px;
          border-radius: 70px;
          top: -40px;
          left: 40%;
          object-fit: cover;
        }
        .cardFooter {
          display: flex;
          justify-content: end;
          margin-top: auto;
          margin-bottom: 10px;
          margin-right: 10px;
          gap: 0.5rem;
        }
      }
    }
    .testimonialCardsWrapper::-webkit-scrollbar {
      display: none;
    }
  }
}
@media screen and (max-width: 1050px) {
  .heroBannerContainer {
    background-image: url("../../../public/images/landingpage1.png");
    width: 100%;
    height: 40vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    flex-direction: column;
    .inner1 {
      display: grid;
      align-items: center;
      padding: 20px 0 0 20px;
      position: relative;

      .text-container {
        padding: 20px 10px;
      }
      h2 {
        margin: 0;
        // font-size: 38px;
        font-style: normal;
        // width: 60%;
        font-weight: 700;
        line-height: 134%;
        letter-spacing: 1.4px;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: 1.4px;
        // text-transform: capitalize;
        span {
          background: var(
            --Linear,
            linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      p {
        margin: 0;
        color: rgba(255, 255, 255, 0.75);
        font-family: Outfit;
        font-size: 15px;
        font-style: normal;
        width: 60%;
        font-weight: 400;
        line-height: 139%; /* 27.8px */
        letter-spacing: 0.4px;
      }
      button {
        position: static;
        height: fit-content;
        right: 300px;
        top: 300px;
        border: none;
        border-radius: 11.62px;
        background: var(
          --Linear,
          linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
        );
        color: #fff;
        width: fit-content;
        gap: 2rem;
        padding: 12px 30px;
        display: flex;
        margin-top: 1rem;
        font-size: 1em;
        cursor: pointer;
        font-weight: 600;
        justify-content: center;
        align-items: center;
      }
    }
    .inner2 {
      display: flex;
      align-items: center;
      margin: 130px 0 0 20px;
      gap: 10px;
      div {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        width: fit-content;
        h3 {
          color: #fff;
          text-align: center;
          font-family: Outfit;
          font-size: 23px;
          font-style: normal;
          font-weight: 700;
          margin: 0;
        }
        p {
          color: rgba(255, 255, 255, 0.87);
          text-align: center;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          width: fit-content;
          margin: 0;
        }
      }
    }
  }
  .testimonialCardsWrapper {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    justify-content: start;
    // overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 2rem;
    width: 97%;
    margin: auto;
    height: 400px;
    padding: 7%;
    .testimonialCard {
      height: 200px;
      // width: 200px;
      position: relative;
      padding: 50px 30px 20px 30px;
      border-radius: 15px;
      background-color: #e1e1e1;
      display: flex;
      flex-direction: column;
      transition: height 0.2s ease, width 0.2s ease;
      p {
        margin: auto;
        font-size: 15px;
      }
      .profilePic {
        position: absolute;
        height: 70px;
        width: 70px;
        border-radius: 70px;
        top: -40px;
        left: 40%;
        object-fit: cover;
      }
      .cardFooter {
        display: flex;
        justify-content: center;
        margin-top: auto;
        margin-bottom: 10px;
        margin-right: 10px;
        gap: 0.5rem;
        white-space: nowrap;
        p {
          font-size: 14px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}

// @media screen and (min-width: 900px) and (max-width: 950px) {
//   .collaborativeProjectContainer {
//     position: relative;
//     height: 30vh;
//     padding: 0 10px;
//     -webkit-user-select: none; /* Safari */
//     -ms-user-select: none; /* IE 10 and IE 11 */
//     user-select: none; /* Standard syntax */
//     .bgVector {
//       display: none;
//     }
//   }
// }

@media screen and (max-width: 900px) {
  .bgVector {
    display: none;
  }
  .collaborativeProjectContainer {
    position: relative;
    height: 70vh;
    padding: 0 10px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    .bgVector {
      display: none;
    }
    //   .collaborativeProjectWrapper {
    //     background-color: #523168;
    //     padding: 15px 0 0 0;
    //     // width: 414px;
    //     margin: 3rem 10px 0 10px;
    //     height: 497px;
    //     border-radius: 20px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     margin: "0px 10px";
    //   }
  }
}

@media screen and (max-width: 600px) {
  .heroBannerContainer {
    background-image: url("../../../public/images/landingpage1.png");
    width: 100%;
    // height: 30vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    flex-direction: column;
    .inner1 {
      display: grid;
      align-items: center;
      padding: 20px 0 0 20px;
      position: relative;

      .text-container {
        padding: 50px 10px 20px 10px;
      }
      h2 {
        margin: 0;
        font-size: 22px;
        font-style: normal;
        // width: 60%;
        font-weight: 700;
        line-height: 134%;
        letter-spacing: 1.4px;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: 1.4px;
        // text-transform: capitalize;
        span {
          background: var(
            --Linear,
            linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      p {
        margin: 0;
        color: rgba(255, 255, 255, 0.75);
        font-family: Outfit;
        font-size: 13px;
        font-style: normal;
        width: 80%;
        font-weight: 400;
        line-height: 139%; /* 27.8px */
        letter-spacing: 0.4px;
      }
      button {
        position: static;
        height: fit-content;
        right: 300px;
        top: 200px;
        border: none;
        border-radius: 11.62px;
        background: var(
          --Linear,
          linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
        );
        color: #fff;
        width: fit-content;
        gap: 2rem;
        padding: 12px 30px;
        display: flex;
        margin-top: 1rem;
        font-size: 1em;
        cursor: pointer;
        font-weight: 600;
        justify-content: center;
        align-items: center;
      }
    }
    .inner2 {
      display: none;
      align-items: center;
      margin: 130px 0 0 20px;
      gap: 10px;
      div {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        width: fit-content;
        h3 {
          color: #fff;
          text-align: center;
          font-family: Outfit;
          font-size: 23px;
          font-style: normal;
          font-weight: 700;
          margin: 0;
        }
        p {
          color: rgba(255, 255, 255, 0.87);
          text-align: center;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          width: fit-content;
          margin: 0;
        }
      }
    }
  }
  .collaborativePartnerContainer {
    padding: 0 10px;
    .collaborativePartnerBox {
      position: relative;
      padding: 20px 40px;
      border: 1px solid #dcdcdc;
      margin: 0 1rem;
      border-radius: 10px;
      height: 220px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      .vextorImage {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }

  .bgVector {
    display: none;
  }
  .collaborativeProjectContainer {
    position: relative;
    height: 70vh;
    padding: 0 10px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    .bgVector {
      display: none;
    }
    //   .collaborativeProjectWrapper {
    //     background-color: #523168;
    //     padding: 15px 0 0 0;
    //     // width: 300px;
    //     margin: 3rem 10px 0 50px;
    //     height: 400px;
    //     border-radius: 20px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     margin: "0px 10px";
    //     .img-wrapper {
    //       .hero-img {
    //         // width: 270px;
    //         // height: 200px;
    //         // margin-top: 70px;
    //       }
    //     }
    //     h6 {
    //       font-size: 12.5px;
    //     }
    //   }
  }
  .testimonialCardsContainer {
    position: relative;
    .testimonialHeader {
      text-align: center;
    }
    .arrowBtn {
      position: absolute;
      top: 50%;
    }
    .testimonialCardsWrapper {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      gap: 2rem;
      width: 85%;
      margin: auto;
      height: 400px;
      padding: 10% 0 0 0%;
      .testimonialCard {
        height: 250px;
        // width: 250px;
        position: relative;
        padding: 50px 30px 20px 30px;
        border-radius: 15px;
        background-color: #e1e1e1;
        display: flex;
        flex-direction: column;
        // margin-left: 40px;
        transition: height 0.2s ease, width 0.2s ease;
        p {
          margin: auto;
          font-weight: 500;
          font-size: 14px;
        }
        .profilePic {
          position: absolute;
          height: 70px;
          width: 70px;
          top: -40px;
          left: 40%;
        }
        .cardFooter {
          display: flex;
          justify-content: center;
          margin-top: auto;
          margin-bottom: 10px;
          gap: 1rem;
          span {
            font-size: 14px;
          }
        }
      }
    }
    .testimonialCardsWrapper::-webkit-scrollbar {
      display: none;
    }
  }
}
@media screen and (max-width: 380px) {
  .collaborativeProjectContainer {
    position: relative;
    height: 90vh;
    padding: 0 10px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    .bgVector {
      display: none;
    }
    //   .collaborativeProjectWrapper {
    //     background-color: #523168;
    //     padding: 15px 0 0 0;
    //     // width: 300px;
    //     margin: 3rem 10px 0 50px;
    //     height: 400px;
    //     border-radius: 20px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     margin: "0px 10px";
    //     .img-wrapper {
    //       .hero-img {
    //         // width: 270px;
    //         // height: 200px;
    //         // margin-top: 70px;
    //       }
    //     }
    //     h6 {
    //       font-size: 12.5px;
    //     }
    //   }
  }
}
