.findingFundersWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 192px 0 0 0;
  position: relative;
  h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.8px;
    margin: 0;
    text-transform: capitalize;
    background: var(
      --Linear,
      linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h2 {
    color: var(--Text-and-Heading, #202123);
    font-size: 30px;
    font-style: normal;
    margin: 20px 0 0 0;
    font-weight: 400;
    letter-spacing: 0.6px;
  }
  .core-button {
    border-radius: 10px;
    width: 229px;
    height: 50px;
    background: var(
      --Linear,
      linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
    );
    font-weight: 500;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
    border: none;
    padding: 10px 20px;
    color: #fff;
    margin: 70px 0 10% 0;
    font-size: 16px;
    cursor: pointer;
  }
  .l1 {
    position: absolute;
    left: 42px;
    top: 161px;
    z-index: 10;
  }
  .l2 {
    position: absolute;
    left: 0;
    top: 90px;
    z-index: 10;
  }
  .r1 {
    position: absolute;
    right: 106px;
    top: 161px;

    z-index: 10;
  }
  .r2 {
    position: absolute;
    right: 0;
    top: 52px;
    z-index: 10;
  }

  img {
    display: block;
    left :"0px";
    z-index: -9;
  }
}
@media screen and (max-width: 1378px) {
  .findingFundersWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0 0 0;
    h1 {
      font-size: 30px;
      font-weight: 700;
    }
    h2 {
      font-size: 20px;
      font-style: normal;
    }
    .core-button {
      width: 229px;
      height: 50px;
      background: var(
        --Linear,
        linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
      );
      font-weight: 500;
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
      border: none;
      padding: 10px 20px;
      color: #fff;
      margin: 50px 0 50px 0;
      font-size: 16px;
      cursor: pointer;
    }
    img {
      display: none;
    }
  }
}
@media screen and (max-width: 600px) {
  .findingFundersWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0 0 0;
    h1 {
      font-size: 30px;
      font-weight: 700;
    }
    h2 {
      font-size: 20px;
      font-style: normal;
    }
    .core-button {
      width: 229px;
      height: 50px;
      background: var(
        --Linear,
        linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
      );
      font-weight: 500;
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
      border: none;
      padding: 10px 20px;
      color: #fff;
      margin: 50px 0 50px 0;
      font-size: 16px;
      cursor: pointer;
    }
    img {
      display: none;
    }
  }
}
