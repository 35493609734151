.main-container {
  // padding: 3% 10% 2% 10%;
  .cohort-heading {
    color: #262626;
    font-family: Outfit;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding-top: 25px;
  }
  .first-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    h2 {
      color: #262626;
      font-family: Outfit;
      font-size: 24px;
      font-weight: 600;
      margin: 0;
    }
    span {
      color: #523168;
      font-size: 20px;
      font-weight: 500;
    }
  }
  p {
    color: rgba(32, 33, 35, 0.87);
    font-family: Outfit;
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
    height: 60px;
  }
  h3 {
    color: var(--a, #523168);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 116.7%;
    margin: 0;
    height: 50px;
    display: flex;

    align-items: end;
  }
  @media screen and (min-width: 899px) and (max-width: 1024px) {
   h3{
    margin-top: 25px
   } ;
  } 
   @media screen and (min-width: 768px) and (max-width: 899px) {
    h3{
     margin-top: 5px
    } ;
   } 
     @media screen and (min-width: 568px) and (max-width: 699px) {
    h3{
     margin-top: 25px
    } ;
   }
   @media screen and (min-width: 320px) and (max-width: 568px) {
    h3{
     margin-top: -30px !important
    } ;
   }
  .third-section {
    display: flex;

    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 170px;
    .inner-container {
      display: flex;
      gap: 6vw;
      align-items: center;
      h4 {
        display: flex;
        flex-direction: column;
        span {
          color: rgba(8, 5, 21, 0.6);
          font-family: Outfit;
          font-size: 14px;
          font-weight: 400;
          line-height: 130%;
        }
        h5 {
          margin-top: 10px;
          color: var(--Heading, #080515);
          font-family: Outfit;
          font-size: 16px;
          font-weight: 400;
          line-height: 130%;
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .main-container {
    .cohort-heading {
      color: #262626;
      font-family: Outfit;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    
    }
    .first-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      h2 {
        color: #262626;
        font-family: Outfit;
        font-size: 24px;
        font-weight: 600;
        margin: 0;
      }
      span {
        color: #523168;
        font-size: 20px;
        font-weight: 500;
      }
    }
    p {
      color: rgba(32, 33, 35, 0.87);
      font-family: Outfit;
      font-size: 16px;
      font-weight: 300;
      line-height: 23px;
      height: 140px;
    }
    h3 {
      color: var(--a, #523168);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 116.7%;
      margin: 0;
      @media screen and (max-width: 417px) {
   
          margin-top: 32px !important;
        
        }
    }
    .third-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-direction: column;
      .inner-container {
        display: flex;
        gap: 1rem;
        align-items: center;
        h4 {
          display: flex;
          flex-direction: row;
          span {
            color: rgba(8, 5, 21, 0.6);
            font-family: Outfit;
            font-size: 14px;
            font-weight: 400;
            line-height: 130%;
          }
          h5 {
            margin-top: 10px;
            color: var(--Heading, #080515);
            font-family: Outfit;
            font-size: 12px;
            font-weight: 400;
            line-height: 130%;
          }
        }
      }
    }
  }
}

// .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
//   padding: 20px;
//   width: 100%;
// }

// .css-1tr4iu0-MuiTableCell-root,
// .css-1pfenbl-MuiTableCell-root,
// .css-1pfenbl-MuiTableCell-root,
// .css-1xiwu4t-MuiTableCell-root {
//   border: none !important;
// }
