.dashBoardCalendar {
  .MuiDateCalendar-root {
    width: 75% !important;
    height: 100% !important;
    background: white !important;
    padding: 10px;
    border-radius: 10px;
  }

  @media screen and (min-width: 450px) {
    .MuiDateCalendar-root {
      max-height: 100% !important;
    }

    .MuiDayCalendar-weekContainer {
      display: flex;
      justify-content: space-around !important;
      padding: 10px 0 !important;
    }

    .MuiPickersDay-root:not(.Mui-selected) {
      font-size: 20px;
    }

    .MuiDayCalendar-weekDayLabel {
      font-size: 14px !important;
    }

    .MuiPickersDay-root,
    .MuiPickersDay-root:not(.Mui-selected) {
      font-size: 20px !important;
    }

    .MuiPickersCalendarHeader-label {
      font-size: 22px;
      font-weight: 700;
    }

    .MuiPickersArrowSwitcher-root {
      gap: 7px;
    }
  }

  .MuiPickersArrowSwitcher-button {
    color: white !important;
    border-radius: 10px !important;
    background: linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%);
  }

  .MuiPickersDay-root.Mui-selected {
    background: linear-gradient(
      98deg,
      #e46703 -1.68%,
      #c7340d 103.45%
    ) !important;
    opacity: 0.9;
    border-radius: 10px !important;
    color: white !important;
    font-size: 20px !important;
  }

  .MuiPickersDay-today {
    background: rgba(233, 104, 13, 0.2) !important;
    border: none !important;
    border-radius: 10px !important;
  }

  .MuiPickersDay-root,
  .MuiButtonBase-root-MuiPickersDay-root {
    width: 40px;
    height: 40px;
  }

  .MuiPickersDay-root.Mui-selected:hover {
    will-change: background-color !important;
    background: linear-gradient(
      98deg,
      #e46703 -1.68%,
      #c7340d 103.45%
    ) !important;
  }

  .MuiPickersDay-root.Mui-selected {
    background-color: rgba(233, 104, 13, 0.2);
  }

  .MuiDayCalendar-header {
    display: flex;
    justify-content: space-around !important;
  }

  .MuiPickersDay-root.Mui-selected:hover {
    background-color: rgba(203, 98, 23, 0.2) !important;
    font-size: 20px !important;
    border-radius: 10px !important;
  }

  @media screen and (max-width: 768px) and (min-width: 450px) {
    .MuiDayCalendar-weekContainer {
      padding: 10px !important;
    }
  }

  .MuiDayCalendar-slideTransition {
    overflow-x: unset !important;
  }

  @media screen and (max-width: 900px) and (min-width: 450px) {
    .MuiDateCalendar-root {
      height: 90% !important;
      max-height: 90% !important;
    }
  }

  @media screen and (max-width: 450px) {
    .MuiDateCalendar-root {
      height: calc(84% - 45px) !important;
      max-height: calc(76% - 10px) !important;
    }

    .MuiDayCalendar-weekContainer {
      padding: 1px !important;
    }

    .MuiButtonBase-root-MuiPickersDay-root {
      font-size: 14px !important;
    }

    .MuiPickersDay-root {
      font-size: 0.75rem !important;
    }

    .MuiPickersDay-root:not(.Mui-selected) {
      font-size: 0.75rem;
    }

    .MuiPickersCalendarHeader-label {
      font-size: 12px;
      font-weight: 300;
    }

    .MuiPickersArrowSwitcher-root {
      gap: 5px !important;
    }

    .MuiPickersArrowSwitcher-button {
      padding: 5px !important;
    }

    .MuiDayCalendar-weekDayLabel {
      font-size: 12px !important;
    }
  }

  .MuiPickersArrowSwitcher-spacer {
    width: 30px !important;
  }
}
.optionContainer {
  display: flex;
  width: 562px;
  margin: 2rem auto auto auto;
  height: 71px;
  padding: 10px;
  align-items: center;
  gap: 40px;
  border-radius: 20px;
  background: linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%);
  div {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #262626;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }
}

@media screen and (max-width: 645px) {
  .optionContainer {
    width: 90%;
    height: 51px;
    padding: 8px;
    div {
      font-size: 15px;
    }
  }
}
