.assessmentPercentage {
  background-image: url("../../../public/discovery/percentageFrame.png");
  height: 178px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 25px 70px;
  div {
    width: 65%;
    display: flex;
    gap: 30px;
    flex-flow: column;
    h2 {
      color: #fff;
      font-size: 30px;
      font-weight: 600;
    }
    div {
      background-color: rgba(255, 255, 255, 0.15);
      width: 100%;
      border-radius: 10px;
      span {
        padding: 5px;
        width: 50%;
        border-radius: 10px;
        background-color: #fff;
      }
    }
  }
  button {
    border-radius: 10px;
    background: #fff;
    border: none;
    color: #0b5cff;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .assessmentPercentage {
    padding: 10px;
    display: grid;
    align-items: center;
    div {
      width: 100%;
      display: flex;
      gap: 30px;
      flex-flow: column;
      h2 {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
      div {
        background-color: rgba(255, 255, 255, 0.15);
        width: 100%;
        border-radius: 10px;
        span {
          padding: 5px;
          // width: 50%;
          border-radius: 10px;
          background-color: #fff;
        }
      }
    }
    button {
      border-radius: 10px;
      background: #fff;
      border: none;
      color: #0b5cff;
      width: fit-content;
      font-size: 14px;
      font-weight: 600;
      padding: 10px 20px;
      margin: auto;
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}
