.profile-heading {
  color: #161e29;
  font-size: 30px;
  font-weight: 600;
  margin-left: 25px;
}

.first-row {
  display: flex;
  gap: 1rem;

  div {
    width: 100%;
  }
  .input {
    width: "50%";
  }
}

.wrapper {
  margin-top: 30px;
}
