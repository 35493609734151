.footerContainer {
  background: #faf7fc;
  // margin-top: 1rem;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .footerInfo {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    // margin-left: auto;
    // margin-right: auto;
    // align-items: center;
    // gap: 14rem;
    .companyImg {
      width: 150px;
      // height: 50px;
    }
    .info1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 16px;

      .inner1 {
        .icons {
          display: flex;
          gap: 10px;
          span {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            background: var(
              --Linear,
              linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
            );
            color: #fff;
          }
        }
      }
      .inner2 {
        margin-top: 35px;
        display: flex;
        gap: 25px;
        span {
          color: rgba(38, 38, 38, 0.87);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          cursor: pointer;
        }
      }
    }

    .contactUS {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      // margin: 0 auto 0 auto;
      gap: 9px;
      p {
        color: #262626;
        font-size: 15px;
        font-weight: 700;
      }
      span {
        color: rgba(38, 38, 38, 0.87);
        font-size: 14px;
        max-width: 270px;
        font-weight: 400;
        margin-top: -4px;
      }
    }
  }
  // .footerBg {
  //   background: #523168;
  //   height: 55px;
  // }
  // .bottomStrip {
  //   // align-items: center;
  //   background: #523168;
  //   // display: flex;
  //   // height: 55px;
  //   // justify-content: space-between;
  //   // padding: 0 9%;
  //   div {
  //     display: flex;
  //     gap: 50px;
  //   }
  //   span {
  //     color: rgba(255, 255, 255, 0.75);
  //     font-size: 13px;
  //     font-weight: 400;
  //     cursor: pointer;
  //   }
  // }
}
@media screen and (max-width: 1067px) {
  .footerContainer {
    background: #faf7fc;
    height: 300px;
    display: flex;
    flex-direction: column;
    .footerInfo {
      margin-top: 40px;
      display: flex;
      // margin-left: auto;
      // margin-right: auto;
      align-items: center;
      gap: 1rem;
    }
  }
}
@media screen and (max-width: 895px) {
  .footerContainer {
    background: #faf7fc;
    height: 450px;
    display: flex;
    flex-direction: column;
    .footerInfo {
      margin-top: 10px;
      display: grid;
      justify-content: center;
      align-items: center;
      gap: 10px;
      // margin-left: auto;
      // margin-right: auto;
      .imgContainer {
        align-self: center;
        margin-left: auto;
        margin-right: auto;
        .companyImg {
          // width: 140px;

          // height: 50px;
          width: 100px;
        }
      }

      .info1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .inner1 {
          .icons {
            display: flex;
            gap: 10px;
            margin-top: 20px;
            span {
              width: 30px;
              height: 30px;
              display: flex;
              cursor: pointer;
              justify-content: center;
              align-items: center;
              border-radius: 50px;
              background: var(
                --Linear,
                linear-gradient(98deg, #e46703 -1.68%, #c7340d 103.45%)
              );
              color: #fff;
            }
          }
        }
        .inner2 {
          margin-top: 20px;
          display: flex;
          gap: 20px;
          span {
            color: rgba(38, 38, 38, 0.87);
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }

      .contactUS {
        display: flex;
        flex-direction: column;
        margin: 10px auto;
        text-align: center;
        gap: 9px;
        p {
          color: #262626;
          font-size: 14px;
          font-weight: 700;
        }
        span {
          color: rgba(38, 38, 38, 0.87);
          font-size: 10px;
          max-width: 270px;
          margin-top: -10px;
          font-weight: 400;
        }
      }
    }
    // .bottomStrip {
    //   background: #523168;
    //   height: 120px;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: space-evenly;
    //   align-items: center;
    //   // padding: 10px 0px;
    //   div {
    //     display: flex;
    //     gap: 12px;
    //     margin-top: 10px;
    //   }
    //   span {
    //     color: rgba(255, 255, 255, 0.75);
    //     font-size: 10px;
    //     font-weight: 400;
    //   }
    // }
  }
}
